<div
  *ngIf="guidedSearchList | async as guidedSearch"
  fxLayout="column"
  fxFlexFill
>
  <div fxFlex="nogrow">
    <div
      class="guided-search-header high-contrast-theme p-6"
      data-cy="guided-search.header"
      [ngClass]="ccssService.getClass('guided_search_header')"
    >
      <div *ngIf="breadcrumbList | async as crumbs">
        <div
          *ngIf="crumbs.length > 0"
          class="guided-search-breadcrumb-top"
          data-cy="guided-search.breadcrumb-top"
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="8px"
        >
          <div fxFlex>
            <span
              *ngFor="
                let breadcrumb of crumbs;
                trackBy: trackByName;
                let i = index
              "
            >
              <span *ngIf="i > 0" class="inline-block margin x-1x">&gt;</span>
              <a
                class="guided-search-breadcrumb font-normal"
                (click)="selectOption(breadcrumb)"
                eventPreventDefault
                [innerHTML]="breadcrumb.name | translate"
                tabindex="0"
                role="button"
              >
              </a>
            </span>
          </div>
        </div>
      </div>

      <div>
        <h3
          class="guided-search-title dls-subhead-1-bold md:dls-heading-3-bold"
          data-cy="guided-search.title"
          [innerHTML]="guidedSearch.name | translate"
        ></h3>
        <div
          *ngIf="
            guidedSearch.description &&
              guidedSearch.primary_specialties_tile &&
              qualityRewardsEligible;
            else basicDescription
          "
          [innerHTML]="
            (guidedSearch.description | translate) +
            ('guided_search_legal_text' | translate)
          "
        ></div>
        <ng-template #basicDescription>
          <div [innerHTML]="guidedSearch.description | translate"></div>
        </ng-template>
      </div>
    </div>
  </div>

  <app-login-prompt
    *ngIf="selectedOption?.unauthenticatedProcedures && !defaultMember"
    [auth]="authStatus"
    [guidedSearchPrompt]="true"
    [headlineKey]="data.headlineKey"
    [subHeadlineKey]="data.subHeadlineKey"
    [noAccountKey]="data.noAccountKey"
    [loginPromptKey]="data.loginPromptKey"
  >
  </app-login-prompt>

  <div
    class="guided-search-options"
    data-cy="guided-search.options"
    [ngClass]="{ 'alpha-list-active': alphaListService.activeList }"
    fxFlex="grow"
  >
    <div
      class="guided-search-option cursor-pointer"
      *ngFor="
        let option of guidedSearch.options;
        trackBy: trackByName;
        index as i
      "
      (keypress)="onKeyPress(option)"
      tabindex="0"
      role="link"
      [attr.data-cy]="'guided-search.option-' + i"
      (click)="selectOption(option)"
    >
      <div
        class="guided-search-option-inner w-full px-6 py-4"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <div
          class="guided-search-option-name w-full"
          [attr.data-cy]="'guided-search.option-name-' + i"
          [ngClass]="ccssService.getClass('guided_search_option_name')"
        >
          <div
            class="link-blue strong"
            [innerHTML]="option.name | translate"
          ></div>
          <div
            zelisEventStopPropagation
            [targetTags]="['a', 'u']"
            class="guided-search-option-description"
            [innerHTML]="option.description | translate"
          ></div>
        </div>
        <mat-icon
          *ngIf="!option.id && option.type !== 'link'"
          class="guided-search-right-icon -mr-4"
          svgIcon="dls-angle-plain-right-light"
        ></mat-icon>
      </div>
    </div>

    <app-alpha-list
      *ngIf="alphaListService.activeList"
      #alphaList
      [listName]="alphaListService.activeList"
      [currentIndex]="0"
      (selected)="selectOption($event.originalItem)"
    >
    </app-alpha-list>
  </div>
</div>
